import { ensureNumber } from '@rsmus/guards'
import {
  Assignment,
  AssignmentUser,
  ClientUser,
  Engagement,
  EngagementMilestone,
  Milestone,
  TeamAssignment,
  UserProfileModel,
} from '../../models'
import { FormModel } from './models'
import moment from 'moment'
import {
  padLeadingZeroes,
  padLeadingZeroesCombined,
} from '../../formatters/padLeadingZeros'


export const dateFormat = 'MM/DD/YYYY'

function getMilestoneDateFields(
  milestones: EngagementMilestone[] | undefined,
  isRollForward: boolean
) {
  let dateFields: { [key: string]: any } = Object.keys(Milestone).reduce(
    (a, m) => ({ ...a, [`${m.toLowerCase()}DateField`]: null }),
    {}
  )

  if (milestones) {
    milestones.forEach((m: EngagementMilestone) => {
      const key = `${m.milestone}DateField`
      if (dateFields.hasOwnProperty(key)) {
        dateFields[key] =
          m.dueDate && !isRollForward
            ? moment(m.dueDate).format(dateFormat)
            : null
      }
    })
  }

  return dateFields
}

function mapClientUsersToAssignments(clientUsers: ClientUser[] | undefined) {
  if (!clientUsers) return []
  return clientUsers.map((cu: ClientUser) => {
    const { type, role, roleDescription, rsmIsFederated, ...rest } = cu
    return {
      roleCode: '',
      user: {
        ...rest,
        isInternal: type === 'Internal',
        eidRole: role,
        eidRoleDescription: roleDescription,
        rsmIsFederated: rsmIsFederated
      },
    }
  })
}

const internalUserIdPattern = /^E\d{6}$/

function getUserType(user: AssignmentUser) {
  const isInternal = user.isInternal || internalUserIdPattern.test(user.userId)
  return isInternal ? 'Internal' : 'External'
}

function getTeamAssignments(
  assignments: Assignment[],
  currentAssignments: TeamAssignment[] = []
) {
  const tempAssignments = assignments.reduce<{
    [userId: string]: TeamAssignment
  }>((a, c) => {
    const existing = currentAssignments.find((x) => x.userId === c.user.userId)
   
    if (!a[c.user.userId]) {
      a[c.user.userId] = {
        isAssigned: existing ? existing.isAssigned : !!c.roleCode,
        firstName: c.user.firstName,
        lastName: c.user.lastName,
        name: `${c.user.lastName}, ${c.user.firstName}`,
        email: c.user.email,
        type: getUserType(c.user),
        userId: c.user.userId,
        roles: existing ? { ...existing.roles } : {},
        eidRole: c.user.eidRole,
        eidRoleDescription: c.user.eidRoleDescription,
        lastLogin:c.user.lastLogin,
        status: c.user.status,
        rsmIsFederated: c.user.rsmIsFederated
      }
    }
    if (c.roleCode) {
      a[c.user.userId].roles[c.roleCode] = true
    }
    return a
  }, {})

  const allAssignments = Object.entries(tempAssignments).map((o) => ({
    // @ts-ignore
    userId: o[0],
    ...o[1],
  }))

  return {
    rsmTeamAssignments: allAssignments.filter((a) => a.type === 'Internal'),
    clientTeamAssignments: allAssignments.filter((a) => a.type === 'External'),
  }
}

export function getMergedClientUserAssignments(
  assignments: Assignment[] = [],
  formAssignments: TeamAssignment[] = [],
  clientUsers: ClientUser[] | undefined
) {
  // Create assignments from client users
  const cuAssignments = mapClientUsersToAssignments(clientUsers)

  // Merge assignments
  let merged: Assignment[] = []
  cuAssignments.forEach((ca) => {
    const existing = assignments.filter((a) => a.user.userId === ca.user.userId)
    if (existing.length) {
      existing.forEach((x) => {
        merged.push({
          ...ca,
          roleCode: x.roleCode,
        })
      })
    } else {
      merged.push(ca)
    }
  })

  return getTeamAssignments(merged, formAssignments)
}

export function getInitialFormValues(
  engagement: Engagement | undefined,
  isRollForward: boolean
) {
  let initialValues: FormModel = {
    assignments: [],
    name: '',
    taxYear: '',
    clientId: '',
    engagementTaxForm: '',
    fundType: '',
    priorYearReturnId: '',
    cchVersion: 1,
    irsDateField: '',
    totalExpectedUnits: 0,
    rsmTeamAssignments: [],
    clientTeamAssignments: [],
    phase: 'setup',
    phaseName: 'RSM Setup',
  }

  if (engagement) {
    const {
      assignments,
      cchVersion,
      clientId,
      completionDate,
      engagementTaxForm,
      engagementTemplateId,
      fundType,
      id,
      milestones,
      name,
      priorYearReturnId,
      taxYear,
      totalExpectedUnits,
       ...rest
    } = engagement

    initialValues = {
      ...initialValues,
      ...(!isRollForward && rest),
      assignments,
      cchVersion: isRollForward ? 1 : ensureNumber(cchVersion),
      clientId: padLeadingZeroes(clientId),
      engagementTaxForm,
      engagementTemplateId,
      name,
      taxYear: isRollForward ? (parseInt(taxYear) + 1).toString() : taxYear,
      priorYearReturnId: buildPriorYearReturnId(engagement, isRollForward),
      ...(id && !isRollForward && { id }),
      ...(fundType && { fundType }),
      ...(totalExpectedUnits && { totalExpectedUnits }),
      ...getMilestoneDateFields(milestones, isRollForward),
      ...getTeamAssignments(assignments),
    }
  }

  return initialValues
}

export function buildPriorYearReturnId(
  engagement: Engagement,
  isRollForward: boolean
) {
  const {
    clientId,
    engagementTaxForm,
    taxYear,
    cchVersion,
    priorYearReturnId,
    client,
  } = engagement

  if (isRollForward && client) {
    const taxFormIndicator: string =
      engagementTaxForm === '990' || engagementTaxForm === '990-EZ' || engagementTaxForm === '990-PF'
        ? 'X'
        : engagementTaxForm === '1065'
        ? 'P'
        : 'unknownTaxForm'

    // pad leading zeroes if needed, and add hyphen between clientId and masterId
    const combinedClientIdMasterId = padLeadingZeroesCombined(
      clientId,
      client.masterId
    )
    return `${taxYear}${taxFormIndicator}:${combinedClientIdMasterId}:V${cchVersion}`
  }

  return priorYearReturnId || ''
}

export function buildSubmitRequest(v: FormModel) {
  // map our model
  const {
    fundType,
    irsDateField,
    setupDateField,
    pbcDateField,
    reviewDateField,
    cchDateField,
    rsmTeamAssignments = [],
    clientTeamAssignments = [],
    // We want to remove phase and phaseName, because
    //  we don't want them going to the server.  Phase changes
    //  happen from a different API.
    phase,
    phaseName,
    ...rest
  } = v

  const request: any = { ...rest }
  // not sure why this is here not needed.
  // the fundtype should be in the formodel allready
  request.fundType = v.fundType

  request.milestones = [
    {
      milestone: Milestone.Irs,
      dueDate: irsDateField && moment(irsDateField, dateFormat),
    },
    {
      milestone: Milestone.Setup,
      dueDate: setupDateField && moment(setupDateField, dateFormat),
    },
    {
      milestone: Milestone.Pbc,
      dueDate: pbcDateField && moment(pbcDateField, dateFormat),
    },
    {
      milestone: Milestone.Review,
      dueDate: reviewDateField && moment(reviewDateField, dateFormat),
    },
    {
      milestone: Milestone.Cch,
      dueDate: cchDateField && moment(cchDateField, dateFormat),
    },
  ]

  // Build entity update list
  // clear Engagment entities 
  request.engagementEntities= []
  const engagementId = ensureNumber(rest.id)

  // Build internal/external assignments list
  request.teamAssignments = [
    ...rsmTeamAssignments
      .filter((a: any) => a.isAssigned)
      .map((a: any) => ({
        id: a.userId,
        roles: a.roles,
      })),
    ...clientTeamAssignments
      .filter((a: any) => a.isAssigned)
      .map((a: any) => ({
        id: a.userId,
        roles: {},
      })),
  ]

  return request
}
